/*eslint-disable*/
import React from "react";

export default {
  en: {
    quote:
      "A reliable and quality solution with a responsive and friendly team",
    author: "Camille Lalanne, Product Manager",
    sector: "Home exchange platform",
    interviewees: [
      "Aldric Monnet, Chief Technology Officer",
      "Camille Lalanne, Product Manager",
    ],
    service: "Dynamic Maps and Geocoding",
    geo: "World",
    blocks: [
      {
        title: "HomeExchange",
        description: (
          <>
            With over 125,000 members in more than 130 countries,
            <a
              href="https://homeexchange.com/"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              HomeExchange
            </a>{" "}
            is a platform that connects individuals from all over the world
            wishing to exchange their homes for short or long stays, at the same
            time or at a later time, and whether or not they are reciprocal.
          </>
        ),
      },
      {
        title: "",
        description: (
          <>
            In their team we have Aldric Monnet, Chief Technology Officer (CTO),
            who structures and organizes the development and deployment teams,
            and is also in charge of the long-term vision in order to remain
            competitive.
          </>
        ),
      },
      {
        title: "",
        description: (
          <>
            We also have Camille Lalanne, Product Manager, in charge of
            defining, developing and managing the product throughout its life
            cycle, as well as gathering needs, identifying opportunities and
            monitoring product performance.
          </>
        ),
        img: "clientLogo",
      },
      {
        title: "HomeExchange & Jawg",
        description: (
          <>
            For HomeExchange, cartographic data plays a key role within the
            platform, the map being at the heart of connecting its members. This
            is why, when their previous provider decided to close their APIs,
            HomeExchange seriously questioned the possibility of managing their
            own OSM server to ensure maximum reliability.
          </>
        ),
      },
      {
        title: "",
        description: (
          <>
            But as Aldric points out,{" "}
            <q>
              cartographic data is a living matter that is often not
              consolidated
            </q>
            . It was therefore better to rely on <q>key players</q> in the
            industry.
          </>
        ),
      },
      {
        title: "",
        description: (
          <>
            With a very tough deadline and in the middle of the summer, the
            project was daunting, but taking the decision to work with Jawg was
            quick:{" "}
            <q>
              No one reassured us like Jawg did with their responsiveness and
              the quality of their responses. We knew we had found the right
              partner to rise to the challenge!
            </q>
          </>
        ),
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <>
            <q>
              The team responds quickly, offers solutions and gives good advice
            </q>{" "}
            whereas with a partner like Google Maps,{" "}
            <q>we’d often be made to feel like customer number 8727-3</q>.
          </>
        ),
      },
      {
        title: "",
        description: (
          <>
            In addition to the expertise and the human aspect, Jawg is 100% made
            in France, like HomeExchange, and as Aldric states:{" "}
            <q>
              I'd rather rely on a French Tech player like Jawg than allocate
              the project budget to an industry giant like Google Maps
            </q>
            .
          </>
        ),
      },
      {
        title: "",
        description: (
          <>
            Thanks to this proximity and regular exchanges, they{" "}
            <a
              href="https://blog.jawg.io/trouvez-votre-prochaine-destination-de-voyage-avec-homeexchange-et-jawg/"
              style={{ textDecoration: "underline" }}
              rel="noopener"
              target="_blank"
            >
              were able to integrate Jawg in record time
            </a>
            . In addition, there were no interruptions of service and the
            scaling was achieved without any roadblocks.
          </>
        ),
      },
      {
        title: "",
        description: (
          <>
            And since then, the collaboration between Jawg and HomeExchange has
            continued to help the platform optimize its search function and
            improve the user experience. When there are flaws in the data, the
            Jawg team reacts quickly to correct and improve the service, a
            win-win for HomeExchange and Jawg.
          </>
        ),
      },
      {
        title: "",
        description: (
          <>
            <q>
              What I really appreciate is that I feel it's a real partnership in
              the sense that we share a common goal which is to provide the best
              map to our users
            </q>
            , adds Aldric.
          </>
        ),
      },
      {
        title: "Jawg Maps in 3 words?",
        description: "Responsiveness, reliability and collaboration",
      },
    ],
  },
  fr: {
    quote:
      "Une solution fiable et de qualité avec un partenaire réactif et enthousiaste",
    author: "Camille Lalanne, Product Manager",
    sector: "Plateforme d’échange de maisons",
    interviewees: [
      "Aldric Monnet, Chief Technology Officer",
      "Camille Lalanne, Product Manager",
    ],
    service: "Dynamic Maps et Geocoding",
    geo: "Monde",
    blocks: [
      {
        title: "HomeExchange",
        description: (
          <>
            Forte de ses plus de 125 000 membres répartis dans plus de 130 pays,{" "}
            <a
              href="https://homeexchange.com/"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              HomeExchange
            </a>{" "}
            est une plateforme permettant de mettre en relation des particuliers
            du monde entier souhaitant échanger leurs maisons pour de courts ou
            longs séjours, au même moment ou en différé, et de manière
            réciproque ou non.
          </>
        ),
      },
      {
        title: "",
        description: (
          <>
            Dans leur équipe, on retrouve Aldric Monnet, Chief Technology
            Officer (CTO), responsable de la structure et de l'organisation des
            équipes de développement et de déploiement du projet, ainsi que de
            la vision à long terme avec comme objectif de rester compétitif.
          </>
        ),
      },
      {
        title: "",
        description: (
          <>
            On a également Camille Lalanne, Product Manager, chargée de définir,
            de développer et de gérer le produit tout au long de son cycle de
            vie, comme de recueillir les besoins, identifier les opportunités et
            suivre les performances du produit.
          </>
        ),
        img: "clientLogo",
      },
      {
        title: "HomeExchange & Jawg",
        description: (
          <>
            Pour HomeExchange, la donnée cartographique occupe une place
            essentielle au sein de la plateforme, la carte étant au cœur de la
            mise en lien entre ses membres. C’est ce pourquoi, quand leur
            précédent fournisseur a décidé de fermer ses APIs, HomeExchange
            s’est sérieusement interrogé sur la possibilité de gérer leur propre
            serveur OSM pour assurer une fiabilité maximale.
          </>
        ),
      },
      {
        title: "",
        description: (
          <>
            Mais comme le souligne Aldric,{" "}
            <q>
              &#160;la data cartographique est une matière vivante assez peu
              consolidée&#160;
            </q>
            . Il valait donc mieux s’appuyer sur des <q>players de référence</q>
            .
          </>
        ),
      },
      {
        title: "",
        description: (
          <>
            Avec une deadline très rude et en pleine période estivale, le
            challenge était de taille mais la décision de travailler avec Jawg a
            été rapide :{" "}
            <q>
              &#160;personne ne nous a rassuré comme Jawg l’a fait, de manière
              si réactive et qualitative. On s’est dit qu’on avait trouvé le bon
              partenaire pour relever le défi !&#160;
            </q>
            .
          </>
        ),
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <>
            <q>
              &#160;L’équipe répond rapidement, propose des solutions et est de
              bon conseil&#160;
            </q>{" "}
            alors qu’avec un partenaire comme Google Maps,{" "}
            <q>&#160;on se sent parfois comme le client numéro 8727-3&#160;</q>.
          </>
        ),
      },
      {
        title: "",
        description: (
          <>
            En plus de l’expertise et de l'aspect humain, Jawg est 100%
            cocorico, comme HomeExchange, et comme le dit si bien Aldric :{" "}
            <q>
              &#160;je préfère m’appuyer sur un acteur de la French Tech comme
              Jawg plutôt que d’allouer le budget lié au projet à un géant du
              secteur comme Google Maps&#160;
            </q>
            .
          </>
        ),
      },
      {
        title: "",
        description: (
          <>
            Grâce à cet aspect humain et des échanges réguliers,{" "}
            <a
              href="https://blog.jawg.io/trouvez-votre-prochaine-destination-de-voyage-avec-homeexchange-et-jawg/"
              style={{ textDecoration: "underline" }}
              rel="noopener"
              target="_blank"
            >
              ils ont pu intégrer Jawg en un temps record
            </a>
            . De plus, il n'y a eu aucune interruption de services et le passage
            à l'échelle a été réussi sans difficulté.
          </>
        ),
      },
      {
        title: "",
        description: (
          <>
            Depuis, la collaboration entre Jawg et HomeExchange continue afin
            d’aider la plateforme d’échange de maisons à optimiser son search et
            d’améliorer l’expérience utilisateur. Quand il y a des défauts dans
            la donnée, l’équipe Jawg réagit rapidement pour corriger et
            améliorer le service, un win-win pour HomeExchange et Jawg.
          </>
        ),
      },
      {
        title: "",
        description: (
          <>
            <q>
              &#160;Ce que j'apprécie vraiment, c’est que je sens que c’est un
              réel partenariat dans le sens où nous partageons un but commun :
              celui de donner la meilleure carte à nos utilisateurs&#160;
            </q>
            , ajoute Aldric.
          </>
        ),
      },
      {
        title: "Jawg Maps en 3 mots ?",
        description: "Réactivité, fiabilité et co-construction",
      },
    ],
  },
};
